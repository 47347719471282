.PricingTableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.PricingTableHeader {
  display: flex;
  width: 100%;
}

.PricingTableEntry {
  display: flex;
  width: 100%;
  padding: 1rem 0rem;
}

.pName {
  width: 20%;
}

.pPrice {
  width: 20%;
}

.pDesc {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.pNailImg {
  width: 30%;
  border-radius: .5rem;
}

.PricingTableHeaderMobile {
  display: none;
}

@media screen and (max-width: 1000px) {
  .PricingTableHeaderMobile {
    display: block;
  }

  .PricingTableHeader {
    display: none;
  }

  .PricingTableEntry {
    width: 100%;
    flex-wrap: wrap;
  }

  .pName {
    padding-right: 1rem;
    width: fit-content;
    min-width: unset;
  }

  .pPrice {
    width: fit-content;
    min-width: unset;
  }

  .pDesc {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-width: unset;
    margin-top: 1rem;
  }

  .pNailImg {
    border-radius: .5rem;
  }  
}
