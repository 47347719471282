:root {
  --background1: #fa9786;
  --nav2: #D2A7A1;
  --title:#a58380;
  --nav: #fafafa;
  --sub1: #D2A7A1;
  --sub2: #D2A7A1;
  --btn: #D2A7A1;
  --btnHover1: #a48d88;
  --btnHover2: #a48d88;
  --border: #fafafa;
}

@font-face {
  font-family: "Pompiere";
  src: url("../public/fonts/Pompiere.ttf");
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins.ttf");
}

.title {
  font-family: "Pompiere";
  font-size: 4rem;
  font-weight: 1000;
  color: var(--title);
  white-space: nowrap;
}

.header {
  font-family: "Pompiere";
  font-size: 3rem;
  font-weight: bolder;
  color: var(--sub1);
}

.sub1 {
  font-family: "Poppins";
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--sub1);
}

.sub2 {
  font-family: "Poppins";
  font-size: 1rem;
  color: var(--sub2);
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 1.5rem;
  width: fit-content;
  cursor: pointer;
  background-color: var(--btn);
  transition: all 0.3s ease-in-out;
  border-radius: 0.5rem;
  color: var(--nav);
  white-space: nowrap;
  text-decoration: none;
}

.btn:hover .btnArrow {
  color: white;
  transform: translate(0.25rem);
}

@keyframes appear {
  0% {
    filter: blur(10px);
  }
  100% {
    filter: blur(0px);
  }
}

/*FOOTER STYLING*/
.footerLogo {
  font-family: "Pompiere";
  font-size: 2rem;
  font-weight: 1000;
  color: var(--nav);
  white-space: nowrap;
  cursor: pointer;
  width: 50%;
}

.footerHeader {
  font-family: "Poppins";
  font-size: 1.25rem;
  font-weight: 1000;
  color: var(--nav);
  white-space: nowrap;
  cursor: default;
  width: fit-content;
}

.footerSub {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 6000;
  color: var(--nav);
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  width: fit-content;
}

.footerCopyright {
  font-family: "Poppins";
  font-size: .9rem;
  font-weight: 6000;
  color: var(--nav);
  text-decoration: none;
  cursor: default;
  width: fit-content;
}

@media screen and (max-width: 600px) {
  .title {
    font-size: 3rem;
    font-weight: 1000;
  }

  .header {
    font-size: 2.5rem;
    font-weight: 1000;
  }

  .btn {
    font-size: .9rem;
    padding: 1rem 1.5rem;
  }

  .sub1 {
    font-size: 1rem;
  }

  .sub2 {
    font-size: .9rem;
  }

  .footerLogo {
    font-size: 1.5rem;
    font-weight: 1000;
  }
  
  .footerHeader {
    font-size: 1.1rem;
    font-weight: 1000;
  }
  
  .footerSub {
    font-size: .9rem;
    font-weight: 6000;
  }
  
  .footerCopyright {
    font-size: .8rem;
    font-weight: 6000;
  }
}
