.LandingPageContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5rem;
  min-height: 60rem;
  overflow-x: hidden;
}

.hidden {
  position: absolute;
  font-size: 0px;
}

.LandingPageWrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blob {
  position: absolute;
  user-select: none;
  width: 50%;
  max-width: 50rem;
  min-width: 20rem
}

.b1 {
  right: -15rem;
  top: -10rem;
}

.b2 {
  left: -20rem;
  top: 10rem;
}

.btnArrow {
  font-size: 1rem;
  margin-left: 1rem;
  transition: all 0.3s ease-in-out;
}

.LPSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.LPInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.LPInner:nth-child(1) {
  margin-top: 7.5rem;
  margin-bottom: 5rem;
}

.LPInner:nth-child(2) {
  height: 440px;
}

.LPInner > .btn {
  margin-top: 3rem;
} 

.nailImg {
  width: 18rem;
  margin: 0rem 1rem;
  border-radius: 0.5rem;
}

@media screen and (max-width: 600px) {
  .LandingPageContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5rem;
    min-height: 50rem;
  }

  .nailImg {
    width: 14rem;
    margin: 0rem 1rem;
    border-radius: 0.5rem;
  }

  .LPInner > .btn {
    margin-top: 2rem;
  } 

  .LPInner:nth-child(1) {
    margin-bottom: 4rem;
  }

  .LPInner:nth-child(2) {
    height: 340px;
  }

  .b1 {
    right: -10rem;
    top: -0rem;
  }
  
  .b2 {
    left: -10rem;
    top: 5rem;
  }
}
