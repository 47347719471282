.FooterContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  position: relative;
  z-index: 1000;
  padding: 0rem 1.5rem;
}

.FooterWrapper {
  background-color: var(--nav2);
  margin: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 80rem;
  padding: 1.5rem;
  border-radius: 0.5rem;
  gap: 3rem;
  flex-wrap: wrap;
}

.FooterLeft {
  display: flex;
  flex-direction: column;
}

.FooterRight {
  display: flex;
  gap: 3rem;
  flex-wrap: wrap-reverse;
}

.FooterCol {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.FooterLeft > .FooterCol {
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.footerSocials {
  display: flex;
  gap: 1rem;
}

.footerSocialIcon {
  color: var(--nav);
  font-size: 1.5rem;
  text-decoration: none;
}

@media screen and (max-width: 1000px) {
  .FooterS1 {
    align-items: center;
    justify-content: none;
  }
}
