.PoliciesContainer {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.PoliciesEntry {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.PoliciesEntry > .sub1 {
  margin-bottom: 0rem;
}
