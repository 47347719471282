.NavContainer {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  z-index: 100;
  padding: 0rem 1.5rem;
}

.NavWrapper {
  background-color: var(--nav2);
  margin: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 80rem;
  padding: 0rem 1.5rem;
  border-radius: 0.5rem;
}

.NavSectionRight {
  display: flex;
  padding: 1.5rem 0rem;
  gap: 1.5rem;
}

.NavSectionLeft {
  display: flex;
  /* padding: 1.5rem 0rem; */
  gap: 1.5rem;
}

.NavItem {
  font-family: "Poppins";
  color: var(--nav);
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  text-decoration: none;
}

.SMIcon {
  font-size: 1.5rem;
}

.logo {
  font-family: "Pompiere";
  font-size: 1.25rem;
  font-weight: 1000;
  border-radius: 0.5rem;
  color: var(--nav);
  display: flex;
  justify-content: center;
  align-items: center;
}

.MobileNavContainer {
  display: flex;
  height: 100vh;
  width: 100%;
}

.MobileLogo {
  cursor: pointer;
  font-size: 1.25rem !important;
  font-weight: 1000 !important;
  width: 18%;
}

.MobileButton {
  font-size: 1.5rem !important;
}

.navLogo{
  cursor: pointer;
  width: 18%;
}

@media screen and (max-width: 1000px) {
  .NavWrapper {
    margin: 0rem 1.5rem;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 80rem;
    padding: 0rem 1.5rem;
    border-radius: 0.5rem;
  }

  .MobileNavContainer > .NavWrapper {
    margin: 0rem;
    border-radius: 0.5rem;
    transform: translateY(-0.75rem);
  }

  .MobileNavContainer {
    display: flex;
    height: fit-content;
    width: 100%;
  }

  .NavItem {
    font-size: .9rem;
    font-weight: 600;
  }

  .MobileNavContainer,
  .MobileNavContainer .NavWrapper,
  .MobileNavContainer .NavWrapper .NavSection,
  .MobileNavContainer .NavWrapper .NavSection .NavItem {
    width: 100%;
  }

  .NavSectionRight {
    flex-direction: column;
  }

  .MobileNavContainer .NavWrapper .NavSection .NavItem {
    padding: 0.5rem 0rem;
  }

  .MobileNavContainer .NavWrapper {
    width: 100%;
  }

  .SMIcon {
    font-size: 1.4rem;
  }

  .NavSection {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0rem;
  }

  .SMRow {
    width: 8rem !important;
    display: flex;
    gap: 1rem;
  }
}
