.FAQContainer {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.FAQEntry {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.FAQEntry > .sub1 {
  margin-bottom: 0rem;
}
